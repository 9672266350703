<template>
  <span class="base-pagination-trigger" @click="onClick">
    {{ pageNumber }}
  </span>
</template>
<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('loadPage', this.pageNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-pagination__description--current {
    color: #00B0F0;
    font-family: 'VWHead Bold';
}
.base-pagination-trigger {
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px;
}

</style>
