<template>
  <div class="base-pagination">
    <carrot class="forward-carrot" v-show="pageCount > 1"
      @click="previousPage"
    >
    </carrot>
     <BasePaginationTrigger
    v-for="paginationTrigger in paginationTriggers"
    :class="{
      'base-pagination__description--current':
        paginationTrigger === currentPage
    }"
    :key="paginationTrigger"
    :pageNumber="paginationTrigger"
    class="base-pagination__description"
    @loadPage="onLoadPage"
  />
    <carrot class="back-carrot"  v-show="pageCount > 1"
      @click="nextPage"
    >
    </carrot>
  </div>
</template>

<script>
import carrot from '@/assets/icons/carrot_up.svg';
import BasePaginationTrigger from '@/components/BasePaginationTrigger.vue';

export default {
  components: {
    carrot,
    BasePaginationTrigger,
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    visiblePagesCount: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },
    isNextButtonDisabled() {
      return this.currentPage === this.pageCount;
    },
    paginationTriggers() {
      const { currentPage } = this;
      const { pageCount } = this;
      let { visiblePagesCount } = this;

      if (pageCount < visiblePagesCount) {
        visiblePagesCount = pageCount;
      }

      const visiblePagesThreshold = (visiblePagesCount - 1) / 2;
      let paginationTriggersArray = [];
      if (pageCount > 1) {
        paginationTriggersArray = Array(visiblePagesCount - 1).fill(0);
      }

      if (pageCount === 1) {
        return [];
      }

      if (currentPage <= visiblePagesThreshold + 1) {
        paginationTriggersArray[0] = 1;
        const paginationTriggers = paginationTriggersArray.map(
          (paginationTrigger, index) => paginationTriggersArray[0] + index,
        );
        paginationTriggers.push(pageCount);
        return paginationTriggers;
      }
      if (currentPage >= pageCount - visiblePagesThreshold + 1) {
        const paginationTriggers = paginationTriggersArray.map(
          (paginationTrigger, index) => pageCount - index,
        );
        paginationTriggers.reverse().unshift(1);
        return paginationTriggers;
      }
      paginationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;
      const paginationTriggers = paginationTriggersArray.map(
        (paginationTrigger, index) => paginationTriggersArray[0] + index,
      );
      paginationTriggers.unshift(1);
      paginationTriggers[paginationTriggers.length - 1] = pageCount;
      return paginationTriggers;
    },
  },
  methods: {
    nextPage() {
      if (!this.isNextButtonDisabled) this.$emit('nextPage');
    },
    previousPage() {
      if (!this.isPreviousButtonDisabled) this.$emit('previousPage');
    },
    onLoadPage(value) {
      this.$emit('loadPage', value);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
